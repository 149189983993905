<template>
  <div ref="imageDiv" class="product-image-div">
    <b-img class="product-image" :src="imageSrc" fluid />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import ImageZoom from 'js-image-zoom'
export default {
  props: {
    imageSrc: { required: true }
  },
  components: {
    BImg
  },
  mounted() {
    let imageZoomOptions = {
      offset: { vertical: 0, horizontal: 10 },
      zoomLensStyle: 'opacity: 0.4;background-color: gray;',
      zoomStyle: 'background-color: rgba(115, 103, 240, 0.1);z-index: 10;border:1px solid #7367f0;'
    }
    ImageZoom(this.$refs.imageDiv, imageZoomOptions)
  }
}
</script>
