<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <b-card v-if="ready" no-body class="overflow-hidden">
        <b-card-body>
          <div class="product-is-new bg-light-success rounded" v-if="product.is_new_product === 1" v-html="$t('shop.is_new')" />
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col cols="12" md="5" class="mb-2 mb-md-0">
              <b-overlay :show="getProductImages().length === 0" variant="dark" opacity="0.85" blur="2px" rounded="sm">
                <div v-if="getProductImages().length" class="product-image-container">
                  <swiper v-if="getProductImages().length > 1" class="swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                    <swiper-slide v-for="(data, index) in getProductImages()" :key="index">
                      <product-image :image-src="data" />
                    </swiper-slide>

                    <!-- Add Arrows -->
                    <div slot="button-next" class="swiper-button-next text-primary" />
                    <div slot="button-prev" class="swiper-button-prev text-primary" />
                  </swiper>
                  <product-image v-else :image-src="getProductImages()[0]" />
                </div>
              </b-overlay>
            </b-col>

            <!-- Right: Product Details -->
            <b-col cols="12" md="7">
              <!-- Product Name -->
              <h4>{{ product.product_name }}</h4>

              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.product_group_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.product_group_name }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.product_code_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.product_code }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.brand_origin_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.brand_name }}/
                  {{ origin }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.orginal_code_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.orginal_code }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.compatible_brands_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.compatible_brands }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-1">
                <span>{{ $t('shop.unit') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.unit_code }}
                </h5>
              </b-card-text>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Static Content -->
        <features :features="product.product_description" />

        <!-- Static Content -->
        <!-- Slider: Related Products -->
        <!-- <related-products /> -->
      </b-card>
    </b-overlay>
  </section>
</template>

<style lang="scss">
.product-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
}

.product-image-div {
  height: 400px;
  width: 400px;
}

.product-image {
  max-height: 400px !important;
}
</style>

<script>
import { BCard, BCardBody, BRow, BCol, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Features from './Features.vue'
import RelatedProducts from './RelatedProducts.vue'
import { GET_PRODUCT } from '@/store/services/shop-service'
import { PUSH_BREADCRUMB } from '@/store/services/breadcrumb-service'
import { mapGetters } from 'vuex'
import ProductImage from './ProductImage.vue'

export default {
  name: 'product-detail',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BOverlay,
    VBTooltip,
    Swiper,
    SwiperSlide,
    // SFC
    Features,
    RelatedProducts,
    ProductImage
  },
  data() {
    return {
      ready: false,
      product: {},
      images: [],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      title: '',
      cartButtonState: false
    }
  },
  created() {
    this.getProduct()
  },
  computed: {
    ...mapGetters(['productImages', 'getLocale', 'defaultProductImage', 'brandLogo']),
    origin() {
      return this.product.origin?.toLocaleUpperCase('tr')
    },
    product_id() {
      return this.$route.params.id * 1
    }
  },
  methods: {
    getProduct() {
      this.ready = false
      this.$store.dispatch(GET_PRODUCT, this.product_id).then(response => {
        this.product = response[0]
        this.title = `${response[0].product_name} - KombiKlimaParca`
        document.title = this.title
        this.$store.commit(PUSH_BREADCRUMB, response[0].product_name)
        this.ready = true
      })
    },
    getProductImages() {
      let tempImages = JSON.parse(this.product.product_images ?? '[]')
      let showImages = []
      if (tempImages?.length) {
        tempImages.forEach(image => {
          showImages.push(`https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${image.image_id}`)
        })
      } else {
        showImages.push(this.brandLogo)
      }
      return showImages
    }
  },
  watch: {
    product_id() {
      this.getProduct()
    },
    getLocale() {
      document.title = this.title
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
//@import "@core/scss/vue/libs/swiper.scss";
@import '~swiper/css/swiper.css';
</style>

<style lang="scss" scoped>
.img-fluid {
  max-width: 500px !important;
}

.product-is-new {
  display: flex;
  position: absolute;
  top: 0.8rem;
  left: -5.2rem;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-size: 9px;
  line-height: 11px;
  font-weight: 900;
  padding: 3px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.discount {
  text-decoration: line-through;
}
</style>
